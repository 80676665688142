import { compileRoutesConfig, routesConfigHelper, StrVal } from "@libs/router/url";
import { ClaimRoutesConfig } from "utils/routing/claims";
import { PatientRoutesConfig } from "utils/routing/patient";
import { ChartingRoutesConfig } from "utils/routing/charting";
import { EmployeeRoutesConfig } from "utils/routing/employees";
import { MessagingRoutesConfig } from "utils/routing/messaging";
import { CommunicationsRoutesConfig } from "utils/routing/communications";
import { SchedulingRoutesConfig } from "utils/routing/scheduling";
import { DashboardRoutesConfig } from "utils/routing/dashboard";
import { ReportingV2RoutesConfig } from "utils/routing/reporting-v2";

import { SettingsRoutesConfig } from "utils/routing/settings";
import { PatientInsuranceRoutesConfig } from "utils/routing/patientInsurance";
import { ErxRoutesConfig } from "utils/routing/erx";
import { ImagingSensorsRoutesConfig } from "utils/routing/sensors";
import { LabCasesRoutesConfig } from "utils/routing/labCases";
import { AuthRoutesConfig } from "utils/routing/auth";
import { OnboardingRoutesConfig } from "utils/routing/onboarding";

export const routesConfig = routesConfigHelper({
  ...ClaimRoutesConfig,
  ...PatientRoutesConfig,
  ...ChartingRoutesConfig,
  ...EmployeeRoutesConfig,
  ...MessagingRoutesConfig,
  ...CommunicationsRoutesConfig,
  ...SchedulingRoutesConfig,
  ...DashboardRoutesConfig,
  ...ReportingV2RoutesConfig,
  ...AuthRoutesConfig,
  ...SettingsRoutesConfig,
  ...ImagingSensorsRoutesConfig,
  ...PatientInsuranceRoutesConfig,
  ...ErxRoutesConfig,
  ...LabCasesRoutesConfig,
  ...OnboardingRoutesConfig,
  ssoZendesk: {
    path: "/sso/zendesk",
    query: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return_to: StrVal,
    },
  },
  ssoLearnupon: {
    path: "/sso/learnupon",
  },
  home: {
    path: "/",
  },
});

export type RoutesConfig = typeof routesConfig;

export const paths = compileRoutesConfig(routesConfig);
export const signedInRedirect = routesConfig.home.path;
export const getRedirectToSignedInUrl = (
  currentUserId: number,
  lastUserId: number | undefined,
  returnUrl?: string | null
) => {
  const hasSignedInAsDifferentUser = Boolean(lastUserId && lastUserId !== currentUserId);

  return hasSignedInAsDifferentUser ? signedInRedirect : (returnUrl ?? signedInRedirect);
};
