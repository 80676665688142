import { FC, PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import { NoInternetConnection } from "@libs/components/UI/NoInternetConnection";

export const AppRoot: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer
        className="z-20"
        toastClassName="py-0 px-4 font-sans text-xs text-greyDark"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NoInternetConnection />
    </>
  );
};
