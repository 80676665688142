import { SignOutReasonVal } from "@libs/router/signOutReasons";
import { BoolVal, NullableBoolVal, NumVal, StrVal, routesConfigHelper } from "@libs/router/url";

const selectAccountPath = "/select-account";

export const AuthRoutesConfig = routesConfigHelper({
  signIn: {
    path: "/auth/sign-in",
    query: {
      returnUrl: StrVal,
      signOutReason: SignOutReasonVal,
      lastUserId: NumVal,
      resetPassword: BoolVal,
    },
  },
  // /auth/first-sign-in is deprecated
  // Once emails start pointing to `/auth/sign-in?resetPassword=1`, this route can be removed
  firstSignIn: {
    path: "/auth/first-sign-in",
  },
  forgotPassword: {
    path: "/auth/forgot-password",
    query: {
      returnUrl: StrVal,
      lastUserId: NumVal,
    },
  },
  supportSignIn: {
    path: "/auth/support",
    query: {
      returnUrl: StrVal,
      signOutReason: SignOutReasonVal,
      lastUserId: NumVal,
    },
  },
  mfaSetup: {
    path: "/mfa-setup",
    query: {
      returnUrl: StrVal,
      lastUserId: NumVal,
      rememberMe: NullableBoolVal,
    },
  },
  selectAccount: {
    path: selectAccountPath,
    query: {
      returnUrl: {
        get(val: string) {
          try {
            // URL throws without an origin
            const url = new URL(val, "https://www");

            // returning the user to the same page would be a bad
            // experience.
            return url.pathname === selectAccountPath ? undefined : val;
          } catch {
            return undefined;
          }
        },
        set(val: string) {
          return val;
        },
      },
      lastUserId: NumVal,
      rememberMe: NullableBoolVal,
    },
  },
  signOut: {
    path: "/sign-out",
    query: {
      returnUrl: StrVal,
      signOutReason: SignOutReasonVal,
      to: StrVal,
    },
  },
  postSignOut: {
    path: "/post-sign-out",
  },
  oauthSignIn: {
    path: "/oauth/sign-in",
  },
});
