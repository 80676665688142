import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { useWindowSize } from "utils/useWindowSize";

function changeOverflow(el: HTMLTextAreaElement, value: string) {
  // Chrome/Safari-specific fix:
  // When the textarea y-overflow is hidden, Chrome/Safari does not reflow the text to account for the space
  // made available by removing the scrollbar. The following forces the necessary text reflow.
  const width = el.style.width;

  el.style.width = "0px";
  // Force reflow
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  el.offsetWidth;
  el.style.width = width;

  el.style.overflowY = value;
}

function resize(el: HTMLTextAreaElement) {
  if (!el.parentElement) {
    return 0;
  }

  // lock the parents height to avoid the screen jumping when scrolled
  el.parentElement.style.height = window.getComputedStyle(el.parentElement).height;

  // set the height of the input to it's natural height
  el.style.height = "";

  // calculate the input base height before adding any necessary offset
  let offset = el.scrollHeight - el.clientHeight;

  if (offset > 0) {
    offset = el.offsetHeight + offset;
    el.style.height = `${offset}px`;
  }

  // reset parent
  el.parentElement.style.height = "";

  return offset;
}

const borderBox = "border-box";

type OverflowYUnion =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "auto"
  | "hidden"
  | "scroll"
  | "visible";
function getNewStyles(el: HTMLTextAreaElement) {
  const newStyles: React.CSSProperties = {
    boxSizing: borderBox,
    height: resize(el),
    overflowY: undefined,
  };
  const styleHeight = Math.round(Number.parseFloat(el.style.height));
  const actualHeight = el.offsetHeight;
  const computed = window.getComputedStyle(el);

  // need to cast as an acceptable value of overflowY
  // the value
  newStyles.overflowY = computed.overflowY as OverflowYUnion;

  if (actualHeight < styleHeight) {
    if (computed.overflowY === "hidden") {
      changeOverflow(el, "scroll");

      const height = resize(el);

      newStyles.height = height;
      newStyles.overflowY = "scroll";
    }
  } else if (computed.overflowY !== "hidden") {
    changeOverflow(el, "hidden");

    const height = resize(el);

    newStyles.height = height;
    newStyles.overflowY = "hidden";
  }

  if (newStyles.height === 0) {
    const { height: _height, ...rest } = newStyles;

    return rest;
  }

  return newStyles;
}

export const useAutoExpand = (inputValue: string) => {
  const windowDimensions = useWindowSize();
  const elRef = useRef<HTMLTextAreaElement | null>(null);
  const [styles, setStyles] = useState<CSSProperties>({
    boxSizing: borderBox,
    overflowY: undefined,
  });
  const update = useCallback(() => {
    const el = elRef.current;

    if (el) {
      const newStyles = getNewStyles(el);

      setStyles(newStyles);
    }
  }, []);

  useEffect(() => {
    update();
  }, [inputValue, update, windowDimensions]);

  return {
    elRef,
    styles,
  };
};
