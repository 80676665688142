import { captureException } from "@sentry/react";
import { millisecondsToSeconds } from "date-fns";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { DAY_IN_HOURS, HOUR_IN_MINUTES, MINUTE_IN_SECONDS } from "@libs/utils/date";
import { getApiClient } from "@libs/api/clients";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";

import { parseQueryParams } from "@libs/router/url";
import { headers } from "utils/headers";
import { paths, routesConfig } from "utils/routing/paths";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";

export const loader =
  ({ baseUrl, storage }: PracticeRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig.mfaSetup.query, url.searchParams);
    const tokens = await getTokensForAuthCheck(storage.localStorage);

    if (!tokens.identity) {
      return redirect(paths.signIn());
    }

    const activityStorage = getPracticeActivityStorage(storage.localStorage);

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut());
    }

    const apiClient = getApiClient({
      baseUrl,
      headers,
      onRequestTokens: () => getTokensForApi(storage.localStorage),
    });

    try {
      const response = await apiClient.user.getUserMfa();
      const mfa = response.data.data;

      if (!mfa.mfaEnforcedAt || mfa.mfaEnabled) {
        return redirect(
          paths.selectAccount({
            rememberMe: query.rememberMe,
            lastUserId: query.lastUserId,
            returnUrl: query.returnUrl,
          })
        );
      }

      // We start enforcing 24 hours before the actual enforcement (72 hours after midnight from the day it was turned on)
      const enforcedAtWithGracePeriod =
        mfa.mfaEnforcedAt - DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS;

      return {
        mfaSetupRequired: millisecondsToSeconds(Date.now()) > enforcedAtWithGracePeriod,
      };
    } catch (e) {
      captureException(e);

      return redirect(paths.signOut({ signOutReason: "GET_USER_MFA_ERROR" }));
    }
  };
