import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { FormSectionTitle } from "components/UI/FormSectionTitle";

export interface BaseFormSectionProps {
  className?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  useCustomStyling?: boolean;
  disabled?: boolean;
  required?: boolean;
  children?: ReactNode;
}

export const BaseFormSection: FC<BaseFormSectionProps> = ({
  title,
  subTitle,
  children,
  className,
  useCustomStyling = false,
  disabled = false,
  required,
}) => {
  return (
    <div className={className}>
      {title ? (
        <FormSectionTitle
          useCustomStyling={useCustomStyling}
          subTitle={subTitle}
          disabled={disabled}
          required={required}
        >
          {title}
        </FormSectionTitle>
      ) : null}
      {children}
    </div>
  );
};

export const FormSection: FC<BaseFormSectionProps> = (props) => {
  return <BaseFormSection {...props} className={cx("py-3 px-8", props.className)} />;
};
