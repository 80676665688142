import { hasItems, Some } from "@libs/utils/array";

/**
 * Creates a batch processor that debounces processing items up to a maximum wait time.
 *
 * @template T The type of the items to be processed.
 * @param {(queue: T[]) => unknown} processQueue The function to process the batch of items.
 * @param {{ debounce: number; maxWait: number }} options
 * options.debounce - The number of milliseconds to wait before processing what is in the queue.
 * options.maxWait - The maximum number of milliseconds to wait before processing what is in the queue.
 * @returns {Object} Returns an object with three methods: `cancel` to cancel processing and discard the queue,
 * `queue` that will add an item to the queue to be processed, and `flush` that will immediately process the remaining items.
 */
export const createBatchProcessor = <T>(
  processQueue: (queue: Some<T>) => unknown,
  settings: { debounce: number; maxWait: number }
) => {
  const { debounce, maxWait } = settings;

  let debounceId: number = 0;
  let maxWaitId: number = 0;
  let queue: T[] = [];

  const runProcessQueue = () => {
    const itemsToProcess = queue;

    if (hasItems(itemsToProcess)) {
      queue = [];
      processQueue(itemsToProcess);
    }
  };

  const clearDebounce = () => {
    if (debounceId) {
      window.clearTimeout(debounceId);
      debounceId = 0;
    }
  };

  const clearMaxWait = () => {
    if (maxWaitId) {
      window.clearTimeout(maxWaitId);
      maxWaitId = 0;
    }
  };

  return {
    getQueue: () => [...queue],
    flush: () => {
      clearDebounce();
      clearMaxWait();
      runProcessQueue();
    },
    cancel: () => {
      clearDebounce();
      clearMaxWait();
      queue = [];
    },
    queue: (item: T) => {
      queue.push(item);

      clearDebounce();
      debounceId = window.setTimeout(() => {
        clearMaxWait();
        debounceId = 0;
        runProcessQueue();
      }, debounce);

      if (!maxWaitId) {
        maxWaitId = window.setTimeout(() => {
          clearDebounce();
          maxWaitId = 0;
          runProcessQueue();
        }, maxWait);
      }
    },
  };
};
