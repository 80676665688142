import { routesConfigHelper, StrVal } from "@libs/router/url";

export const ReportingV2RoutesConfig = routesConfigHelper({
  reportingV2AskAI: {
    path: "/reporting/ask-ai",
  },
  reportingV2Landing: {
    path: "/reporting",
  },
  reportingBoard: {
    path: "/reporting/board/:boardId",
    params: { boardId: StrVal },
  },
});
