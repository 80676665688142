import { EobPaymentSummaryVO } from "@libs/api/generated-api";
import {
  NumCSV,
  StrVal,
  withDefault,
  ParsedParams,
  routesConfigHelper,
  Enum,
  NumVal,
  StrCSV,
} from "@libs/router/url";
import {
  ClaimInsuranceOrdinal,
  ClaimInsurancePaymentStatus,
  ClaimState,
  FilterStatus,
} from "components/Claims/types";
import { SelectedPaymentType } from "components/Eob/useSubmitDraftEobPayment";

export type SortDirection = "ASCENDING" | "DESCENDING";
export type SortField =
  | "SERVICE_DATE"
  | "PATIENT_LAST_NAME"
  | "TREATING_PROVIDER_LAST_NAME"
  | "SUBMITTED_AT"
  | "NEEDS_ATTACHMENT";

export type EOBSortField =
  | "createdDate"
  | "completedDate"
  | "paymentMethod"
  | "numberClaims"
  | "totalInsurancePaymentAmount"
  | "patientAmount"
  | "state"
  | "paymentDate";

const DEFAULT_SORT_FIELD: SortField = "SERVICE_DATE";
const DEFAULT_SORT_DIRECTION: SortDirection = "DESCENDING";

export const ClaimsQueryParams = {
  billingProviderIds: NumCSV,
  insuranceCarrierIds: NumCSV,
  insuranceOrdinals: Enum<ClaimInsuranceOrdinal>().CSV(["PRIMARY", "SECONDARY", "OTHER"]),
  insurancePaymentStatuses: Enum<ClaimInsurancePaymentStatus>().CSV([
    "UNPAID",
    "NOT_SUBMITTED",
    "PAID_AS_EXPECTED",
    "OVERPAID",
    "UNDERPAID",
    "DENIED",
  ]),
  search: StrVal,
  sortField: withDefault(
    Enum<SortField>().Val([
      "SERVICE_DATE",
      "PATIENT_LAST_NAME",
      "TREATING_PROVIDER_LAST_NAME",
      "SUBMITTED_AT",
      "NEEDS_ATTACHMENT",
    ]),
    DEFAULT_SORT_FIELD
  ),
  sortDirection: withDefault(Enum<SortDirection>().Val(["ASCENDING", "DESCENDING"]), DEFAULT_SORT_DIRECTION),
  states: Enum<ClaimState>().CSV(["PENDING", "SUBMITTED", "FAILED", "COMPLETED", "SUBMITTING", "VOIDED"]),
  statuses: Enum<FilterStatus>().CSV([
    FilterStatus.COMPLETED,
    FilterStatus.NOT_SUBMITTED,
    FilterStatus.SUBMITTED,
    FilterStatus.VOIDED,
  ]),
  treatingProviderIds: NumCSV,
};

const ClaimPathParams = { claimUuid: StrVal };
const ClaimQueryParams = { from: StrVal };
const EobPathParams = { eobUuid: StrVal };

type EobStep = "process-payment" | "completed" | "add-claims" | "edit-eob";

const EobQueryParams = {
  ...ClaimQueryParams,
  step: withDefault(
    Enum<EobStep>().Val(["edit-eob", "add-claims", "process-payment", "completed"]),
    "completed"
  ),
  paymentTypeRecorded: Enum<Extract<SelectedPaymentType, "CHECK" | "EFT">>().Val(["CHECK", "EFT"]),
};

const NewEobQueryParams = {
  ...ClaimQueryParams,
  claimUuids: withDefault(StrCSV, []),
};

export const EOB_PAYMENT_METHODS: NonNullable<EobPaymentSummaryVO["paymentMethod"]>[] = [
  "CHECK",
  "EXTERNAL_POS",
  "EFT",
  "STORED_PROFILE",
] as const;

const EobsQueryParams = {
  search: StrVal,
  sortField: withDefault(
    Enum<EOBSortField>().Val([
      "createdDate",
      "completedDate",
      "paymentMethod",
      "numberClaims",
      "patientAmount",
      "totalInsurancePaymentAmount",
      "state",
      "paymentDate",
    ]),
    "createdDate"
  ),
  sortDirection: withDefault(Enum<SortDirection>().Val(["ASCENDING", "DESCENDING"]), DEFAULT_SORT_DIRECTION),
  insuranceCarrierIds: NumCSV,
  patientAmountLe: NumVal,
  patientAmountGe: NumVal,
  totalInsurancePaymentAmountGe: NumVal,
  totalInsurancePaymentAmountLe: NumVal,
  paymentMethods: Enum<NonNullable<EobPaymentSummaryVO["paymentMethod"]>>().CSV(EOB_PAYMENT_METHODS),
  states: Enum<NonNullable<EobPaymentSummaryVO["state"]>>().CSV(["COMPLETED", "DRAFT", "PAYMENT_PENDING"]),
};

export const ClaimRoutesConfig = routesConfigHelper({
  preAuths: { path: "/claims/pre-auths", query: ClaimsQueryParams },
  claims: { path: "/claims", query: ClaimsQueryParams },
  eobs: { path: "/eobs", query: EobsQueryParams },
  eobNew: { path: "/eobs/new", query: NewEobQueryParams },
  eob: { path: "/eobs/:eobUuid", query: EobQueryParams, params: EobPathParams },

  claim: {
    path: "/claims/:claimUuid",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimAdjustment: {
    path: "/claims/:claimUuid/add-adjustment",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimAttachments: {
    path: "/claims/:claimUuid/attachments",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimEobs: {
    path: "/claims/:claimUuid/eobs",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
  claimHistory: {
    path: "/claims/:claimUuid/history",
    params: ClaimPathParams,
    query: ClaimQueryParams,
  },
});
export type ClaimsQuery = ParsedParams<typeof ClaimsQueryParams>;
export type ClaimsQueryUpdates = Partial<ClaimsQuery>;
export type EobsQuery = ParsedParams<typeof EobsQueryParams>;
export const EobsQueryKeys = Object.keys(EobsQueryParams) as (keyof EobsQuery)[];
