type StringOrNumberKeys<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends number | string ? K : never;
}[keyof T];

type ValueType<T extends Record<string, unknown>, K extends StringOrNumberKeys<T>> = T[K] extends
  | number
  | string
  ? T[K]
  : never;

/**
 * Transforms an array of objects into an object where the keys are determined by the provided key parameter.
 *
 * @template O The type of the objects in the input array. Must extend Record<string, string>.
 * @template K The type of the key used to index the objects. Must be a key of O.
 * @param {O[] | Readonly<O[]>} items - The array of objects to be transformed.
 * @param {K} key - The key to be used to index the objects.
 * @returns {Record<O[K], O>} Returns an object where the keys are determined by the provided key parameter.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keyBy = <O extends Record<string, any>, K extends StringOrNumberKeys<O>>(
  items: O[] | Readonly<O[]>,
  key: K
) => {
  const result = {} as Record<ValueType<O, K>, O>;

  items.forEach((item) => {
    const keyValue = item[key] as ValueType<O, K>;

    result[keyValue] = item;
  });

  return result;
};
