import { InsuranceCarrierVO } from "@libs/api/generated-api";

export const getInsuranceCarriersMap = (insuranceCarriers: InsuranceCarrierVO[]) => {
  const map = insuranceCarriers.reduce<Record<number, InsuranceCarrierVO | undefined>>((acc, carrier) => {
    acc[carrier.id] = carrier;

    return acc;
  }, {});

  return map;
};
