import { redirectDocument, redirect, LoaderFunctionArgs } from "react-router-dom";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { parseQueryParams } from "@libs/router/url";
import { getFullUrl } from "@libs/utils/location";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";
import { routesConfig, getRedirectToSignedInUrl, paths } from "utils/routing/paths";

export const authLoader =
  ({ storage }: PracticeRouterContext, routeName: "signIn" | "forgotPassword" | "supportSignIn") =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig[routeName].query, url.searchParams);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);
    const resetPassword = "resetPassword" in query && query.resetPassword;

    if (routeName === "signIn" && resetPassword) {
      if (tokens.identity) {
        return redirect(paths.signOut({ to: getFullUrl(new URL(request.url)) }));
      }
    } else if (activityStorage.isRecentlyActive()) {
      if (tokens.account) {
        return redirectDocument(
          getRedirectToSignedInUrl(tokens.account.userId, query.lastUserId, query.returnUrl)
        );
      }

      if (tokens.identity) {
        return redirect(paths.selectAccount({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
      }
    } else if (tokens.identity) {
      return redirect(paths.signOut());
    }

    return null;
  };
